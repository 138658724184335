export default [
    {
        path: "/home",
        name: "home",
        component: () => import("../views/homepage"),
    },
    {
        path: "/home2",
        name: "home2",
        component: () => import("../views/homepage2"),
    },
    {
        path: "/home3",
        name: "home3",
        component: () => import("../views/homepage3"),
    },
    {
        path: '/success-order',
        name: 'success-order',
        component: () => import('../views/success-order'),
    },
    {
        path: "/checkout",
        name: "checkout",
        component: () => import("../views/checkout"),
    },

    {
        path: "/:catchAll(.*)",
        redirect: "/home"
    }
];